import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

const twIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" className="inline-block w-4 h-4 mr-1 face-twitter">
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  )
}

export default function OurTeam() {
  return (
        <div className="bg-white" id="meet_our_team">
          <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-8 sm:space-y-12">
              <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                <h2 className="text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">OUR TEAM</h2>
                <p className="text-lg text-gray-900">
                  Our team of experienced traders is here to take you through the markets, offering their analysis and discussing market news & developments
                </p>
              </div>

              <ul
                role="list"
                className="mx-auto grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-3 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-3"
              >

                <li className="" >
                  <div className="space-y-4">
                    <a href="/our-team#ryan">
                      <StaticImage
                        class="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24 cursor-pointer"
                        src="../images/ryan.jpg"
                        alt=""
                      />
                    </a>
                    <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3>Ryan Littlestone</h3>
                        <a href="https://twitter.com/forexflowlive" target="_blank" className="face-twitter hover:underline block mb-2">
                        {twIcon()}@forexflowlive
                        </a>
                        <p className="text-greay-50">Managing Director, trader and analyst at ForexFlow.live</p>
                      </div>
                    </div>
                  </div>
                </li>


                <li className="" >
                  <div className="space-y-4">
                    <a href="/our-team#koen">
                      <StaticImage
                        class="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24 cursor-pointer"
                        src="../images/koen.jpg"
                        alt=""
                      />
                    </a>
                    <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3>Koen Vanderschrick</h3>
                        <a href="https://twitter.com/KVanderschrick" target="_blank" className="face-twitter hover:underline block mb-2">
                        {twIcon()}@KVanderschrick
                        </a>
                        <p className="text-greay-50">Foreign Exchange Manager at Independent Forex Trader</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="" >
                  <div className="space-y-4">
                    <a href="/our-team#stelios">
                      <StaticImage
                        class="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24 cursor-pointer"
                        src="../images/stelios.jpg"
                        alt=""
                      />
                    </a>
                    <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3>Stelios Kontogoulas</h3>
                        <a href="https://twitter.com/SteliosConto" target="_blank" className="face-twitter hover:underline block mb-2">
                        {twIcon()}@SteliosConto
                        </a>
                        <p className="text-greay-50">Co-founder at ForexAnalytix</p>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
  )
}
