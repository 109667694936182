/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import Testimonials from "./testimonials"
import OurTeam from "./our_team_section"
// import ForexSection from "./forex-section"
import ForexSection from "./forex-section3"
import ImageLeftSection from "./image_left_section"
// import ImageRightSection from "./image_right_section"
// import Video from "./video"
import NewsLetter from "./newsletter"
// import Contributors from "./contributors"
import Cta from "./cta"

import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const location = useLocation();
  console.log('>>>>', location);

  return (
    <>
      <CookieConsent
        location="bottom"
        cookieName="flow-show-gdpr-tagmanager"
        style={{ background: 'rgb(51,51,51)', padding: '15px' }}
        buttonText="Accept"
        buttonStyle={{
          backgroundColor: '#FFF',
          color: '#000',
          fontSize: '13px',
          borderRadius: '5px',
        }}
        enableDeclineButton
        declineButtonText="Decline"
        declineButtonStyle={{
          backgroundColor: '#555',
          color: '#efefef',
          fontSize: '13px',
          borderRadius: '5px',
        }}
        onAccept={() => {
        initializeAndTrack(location)
      }}
      >
      This website uses cookies to enhance the user experience. Your privacy is important to us, and we will never sell your data. <br/><a style={{textDecoration: 'underline'}} href="/privacy">Privacy Policy</a>
      </CookieConsent>

      <Header />
      <main>
        <ForexSection  />
        <ImageLeftSection />
        <OurTeam />
        <Testimonials />
        <NewsLetter />
        <Cta />
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
