import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import logoFa from "../images/forex-analytix-logo.png"
import logoTraderFunding from "../images/trader-funding-program-logo.png"

export default function ForexSection() {
  let reversed = false;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }
  return (
    <div className="bg-white" id="forex-section">
      <div className="max-w-7xl mx-auto py-12 px-4 lg:text-center sm:px-6 lg:px-8 lg:py-16">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl text-center">Forex Analytix</h2>

              <div className="pt-0 sm:pt-0 lg:pt-2">
                  <p className="mt-4 text-lg text-gray-900">
                  <a href="https://www.forexanalytix.com/" class="primary hover:underline font-bold" target="_blank">Forex Analytix</a> is proud to host the free The Flow Show as a part of its service. Our company’s goal is to <b>educate and help traders be more successful, and The Flow Show is a great vehicle for individuals to get ideas, analysis, and market commentary.</b>
                  </p>
                  <p className="mt-4 text-lg text-gray-900">
                  Our range of services include <b>free trading shows, daily analysis, exclusive members-only chatrooms and much more</b> – all delivered via a state-of-the-art platform. We also have dedicated smartphone and tablet apps to make sure that our service is delivered to you wherever you may be.
                  </p>
              </div>

              <div className="pt-12 mb-5 text-center text-xl text-gray-500">
                Sponsored by
              </div>

              <div className="mb-10 max-w-4xl mx-auto flex justify-center gap-16">
                <a href="https://traderfundingprogram.com/" target="_blank">
                  <img
                    className="h-32 mx-auto"
                    src={logoTraderFunding}
                    alt="traderfundingprogram sponsor"
                  />
                </a>
              </div>

          </div>
        </div>
      </div>
    </div>
    )
}
