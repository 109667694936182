import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import img from "../images/ryan-banner.jpg"

export default function ImageLeftSection() {
  let reversed = false;
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ")
  }
  return (
      <div className="relative py-16 overflow-x-hidden face-gray-bg sm:py-6" id="the-flow-show">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div
            className={classNames(
              reversed ? "order-2" : "",
              "relative sm:py-16 lg:py-0"
            )}
          >
            <div
              aria-hidden="true"
              className={classNames(
                reversed ? "lg:left-0" : "lg:right-0",
                "hidden sm:block lg:absolute lg:w-screen lg:inset-y-0 "
              )}
            >
              <div
                className={classNames(
                  reversed
                    ? "left-1/2 lg:left-72"
                    : "right-1/2 lg:right-72",
                  "absolute inset-y-0 w-full bg-gray-50"
                )}
              />
            </div>
            <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative overflow-hidden shadow-xl pt-[65.75%]">
                <a
                  href="https://forexanalytix.zoom.us/webinar/register/WN_okUu7snZRI2cfjR5mSNXsw"
                  target="_blank"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white primary-bg primary-btn-hover"
                >
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={img}
                    alt="hosted by Ryan Littlestone"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-18 lg:pt-18 lg:mt-2">
                <h2 className="hidden text-3xl font-extrabold tracking-tight sm:text-4xl text-gray-900">
                  The Flow Show
                </h2>

                <p className="mt-4 text-lg text-gray-900">
                  <b>Bringing you the latest market news, views and trading moves!</b>
                </p>

                <p className="mt-4 text-lg text-gray-900">
                  The Flow Show is hosted by <b><a href="/our-team" className="primary hover:underline">Ryan Littlestone</a></b> every weekday at 10:30 am UK time.
                </p>

                <p className="mt-4 text-lg text-gray-900">
                  It lasts for 45-60 minutes and <b>it’s free for anyone to join</b>.
                </p>

                <p className="mt-4 text-lg text-gray-900">
                  The Flow Show aims to bring you the latest updates from the Asia and European sessions.
                  We'll also talk about what's driving markets and how the intraday and longer-term themes may have changed.
                </p>
                <p className="mt-4 text-lg text-gray-900">
                  We aim to teach and cut through the jargon surrounding the fundamentals, as well as talking about trading dos and don'ts. <b>Most importantly, we want your input, so have your questions ready.</b>
                </p>
            </div>

          </div>
        </div>
      </div>
    )
}
