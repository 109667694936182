import React, { useState, useEffect } from "react"
import pboockvar from "../images/twitter/pboockvar.png"
import jackschwager from "../images/twitter/jackschwager.jpg"
import petergoodburn from "../images/twitter/petergoodburn.jpg"
import marcmakingsense from "../images/twitter/marcmakingsense.png"
import stocktonkatie from "../images/twitter/stocktonkatie.jpg"
import fx_button from "../images/twitter/fx_button.png"
import LukeGromen from "../images/twitter/LukeGromen.jpg"
import hmeisler from "../images/twitter/hmeisler.jpg"
import JustinHertzberg from "../images/twitter/JustinHertzberg.jpeg"

const twIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 24 24" className="inline-block w-4 h-4 mr-1 face-twitter">
      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    </svg>
  )
}

export default function Testimonials() {
  const [state, setState] = useState(false);

  useEffect(() => {
    import('tw-elements');
    setState({

      "Peter Goodburn": "Dale is the ultimate wisdom-keeper of financial markets – during many occasions he’s interviewed me and when I’ve watched his other interviews, he’s always amazed in the depth of his experience and knowledge of how markets function, how current price development occurs across all the asset classes – but more than that, his humane and ineffable character gets the most out of his guests – I’m a huge fan!",
      "Peter Goodburn_short": "Dale is the ultimate wisdom-keeper of financial markets – during many occasions he’s interviewed me and when I’ve watched ",
      "marcmakingsense": "I  have been interviewed by quite smart journalists and anchors on TV.  At their best, the interviews are informative and educational.  However, when Dale Pinkert \"interviews\" me, it is quite a different experience.  It is not so much Q&A exercise, but a discussion between two thoughtful people who have wrestled with the markets for more years than they care to admit.   Our scar tissues  can generate insight, but also humility.  If our species is going to evolve, which there is not guarantee, it is because we can learn from others' mistakes.  How to balance that with the confidence needed to trade is what Dale does best.",
      "marcmakingsense_short": "I have been interviewed by quite smart journalists and anchors on TV.  At their best, the interviews are informative and educational.  However",
      "fx_button": "One of the best ways to understand what’s happening in markets is to talk to other people and there is no one better to talk with than Dale Pinkert. Every interview with Dale over many years has been a wonderful opportunity to talk about markets but also to learn from one of the best. Dale’s curiosity, openness, knowledge and interviewing skill have attracted loyal followers around the world and I’m one of them.",
      "fx_button_short": "One of the best ways to understand what’s happening in markets is to talk to other people and there is no one better to talk with than Dale Pinkert. Every interview",
      "justin": "Over the years, I've have the pleasure of being interviewed by Dale Pinkert with Forex Analytix. Forex Analytix seeks to provide market insights and ideas to its audience on a daily basis. As its moderator and interviewer, Dale is able to deftly steer conversations with his interviewees to extract the views, opinions, ideas and outlooks that help the Forex Analytix audience to think critically about market drivers and investment decisions. While challenging, Dale is thoughtful and conscientious and as an interviewer and I always look forward to speaking with him and sharing my thoughts with the Forex Analytix membership.",
      "justin_short": "Over the years, I've have the pleasure of being interviewed by Dale Pinkert with Forex Analytix. Forex Analytix seeks to provide market insights and ideas to its audience on a daily basis. As its moderator and interviewer",
    })
  }, []);

  const trunc =(person) => {
    if (state[person] == undefined) return ""
    if (state[person+"_short"])
      if (!state[person+"_show_all"]) {
        return (
        <span>
          {state[person+"_short"]} ...&nbsp;
          <a className="underline text-sm italic" href="javascript:void(0)"
            onClick={()=>setState({...state, [person+"_show_all"]: !state[person+"_show_all"]})}>[show more]</a>
        </span> )
      } else {
        return (
        <span>
          {state[person]} &nbsp;
          <a className="underline text-sm italic" href="javascript:void(0)"
            onClick={()=>setState({...state, [person+"_show_all"]: !state[person+"_show_all"]})}>[show less]</a>
        </span> )
      }
    else
      return state[person]
  }

  return (
    <div className="face-gray-bg" id='testimonials'>
      <section className="max-w-7xl mx-auto py-16 px-4 sm:py-18 sm:px-6 lg:px-8 mb-0 text-gray-700">
          <div className="mb-16 text-center md:max-w-xl lg:max-w-3xl mx-auto">
            <h3 className="text-3xl font-bold mb-6 text-gray-900">TESTIMONIALS</h3>
            {/*<p className="mb-6 pb-2 md:mb-12 md:pb-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam
              iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
              porro a pariatur veniam.
            </p>
            */}
          </div>

          <div className="grid md:grid-cols-3 gap-6 lg:gap-12 text-center">
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                  I listen in every night from the desert in Australia. I find the flow show solid education. High quality analysis. - Su Hely
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                The BEST trading community to be in. Addicted listening to you all (Flow Show, FACE, Morning Edge, Trufa, Daily Roundup), and reading the chatbox. Every single day the best team is a must!
                <br/>
                Do not change anything, it is superb. - Laura Gio
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                The show is excellent and the breath of advice and market insight is perfect for traders like me who are building a career and need guidance and dialogue. - Andrew Peart
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                I am very appreciative of this early morning webinar. It has meant a lot to the progress in my trading journey. thank you to all! - AWC Chambers

              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                Wish I could listen in more often. All 3 of you guys, Ryan, Kman and Stelios give great macro insight as well as what's underneath of key importance. Many thanks and keep it up!! - Chris Merner
              </p>
            </div>

            {/*=========================*/}
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                Cheers guys...one of the best shows out there hands down. - Jordan Robertson
              </p>
            </div>
{/*
            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                the best market open webinar around - Nik Shah
              </p>
            </div>

            <div className="mb-12 md:mb-0">
              <p className="mb-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="quote-left"
                  className="w-6 pr-2 inline-block"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
                  ></path>
                </svg>
                Thanks a lot for the highlight on trade management. - Mercy & Danielle
              </p>
            </div>
*/}

          </div>

      </section>
    </div>
  )
}
