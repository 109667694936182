import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import logo from "../images/logo-header-white2.png"
import logoBlack from "../images/logo-black.png"
import { Helmet } from "react-helmet"
import heroBanner from "../images/hero-banner.jpg"

const navigation = [
  { name: 'The Flow Show', href: '#the-flow-show' },
  { name: 'Our team', href: '#meet_our_team' },
  { name: 'Testimonials', href: '#testimonials' },
  { name: 'Forex Analytix', href: 'https://www.forexanalytix.com/' },
  // { name: 'Our Team', href: '/our-team' },
]

export default function Header() {
  return (
    <div className="relative primary-bg">
      <Helmet title="The Flow Show"/>
      <Popover className="relative bg-black shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-1 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">The Flow Show</span>
                <img
                  className="w-auto py-6" style={{maxHeight: '120px'}}
                  src={logo}
                  alt="The Flow Show Header"
                />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">

              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="font-bold text-base font-medium text-white primary-hover">
                  {item.name}
                </a>
              ))}

            </Popover.Group>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="https://forexanalytix.zoom.us/webinar/register/WN_okUu7snZRI2cfjR5mSNXsw"
                target="_blank"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-white shadow-sm text-base font-bold text-white bg-black primary-black-btn-hover"
              >
                Register For Free
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-16 w-auto"
                      src={logoBlack}
                      alt="The FLow Show Logo"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white p-2 inline-flex items-center justify-center text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="text-base font-bold text-gray-900 primary-hover">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  <a
                    href="https://forexanalytix.zoom.us/webinar/register/WN_okUu7snZRI2cfjR5mSNXsw"
                    target="_blank"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-black font-bold orange-bg primary-btn-hover"
                  >
                    Register For Free
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-16">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="text-black mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-5xl">
                <span className="block xl:inline">THE LATEST</span>{' '}
                <span className="block xl:inline">MARKET NEWS, VIEWS</span>{' '}
                <span className="block xl:inline">AND TRADING MOVES!</span>
              </span>
            </h1>
            <p className="mt-3 text-base primary font-bold sm:mt-5 sm:text-xl lg:text-xl xl:text-2xl">
              World class trading education <br/>
              and actionable analysis daily.
            </p>

            <div className="mt-10 mb-10 sm:flex sm:justify-center lg:justify-start">
              <div className="shadow">
                <a
                  href="https://forexanalytix.zoom.us/webinar/register/WN_okUu7snZRI2cfjR5mSNXsw"
                  target="_blank"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-black primary-hover md:py-4 md:text-lg md:px-10"
                >
                  Free Registration
                </a>
              </div>
            </div>
          </div>

          {/* <div className="primary-bg pb-20 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full lg:max-w-xl">
                <span className="sr-only"></span>
                <div className="relative text-center aspect-w-16 aspect-h-9">
                  <img
                    className="mx-auto"
                    src={heroBanner}
                    alt="Company name"
                  />
                </div>
            </div>
          </div> */}

          <div className="face-black pb-20 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full shadow-lg lg:max-w-xl">
                <span className="sr-only">Watch our video to learn more</span>
                <div className="relative text-center aspect-w-16 aspect-h-9">
                  <iframe
                    loading="lazy"
                    title="video"
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/B9fhn8zXnbw"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
            </div>
          </div>

        </div>
      </main>

    </div>
  )
}
